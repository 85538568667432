@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@200;300;400;500;600;700;800&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Arizonia&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Quicksand:wght@300..700&family=Raleway+Dots&family=Roboto&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "quicksand", sans-serif;
}

.container {
  max-width: 1170px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

section {
  padding: 0px 0;
}

/* .sticky_header{
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  background: white  ;
  box-shadow: 3px 3px -8px 3px #fff;
  z-index: 99;
} */
.sticky_header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #E2D0F2 ; /* Set the background color */
  opacity: 0.9; /* Set the opacity value */
  box-shadow: 3px 3px -8px 3px #fff;
  z-index: 99;
}



@media only screen and (max-width: 768px){
  .menu {
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left: 0;
    background: #00000084; 
    z-index: 100;
    display: none;
  }


.menu ul{
  width: 15rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;
  background: #fff;
  flex-direction: column;
  line-height: 30px;
  justify-content: center;
  align-items: center;

}



.show_menu {
  display: block;
}

}


/* Footer.css */

.sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333; /* Customize the background color */
  color: #fff; /* Customize the text color */
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #333; /* Customize the box shadow */
  z-index: 99;
}

.footer-content {
  max-width: 1170px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-info {
  flex: 1;
  max-width: 300px;
}

.footer-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.contact-info,
.social-links {
  flex: 1;
  max-width: 300px;
}

.social-links a {
  display: block;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ffcc00; /* Customize the link color on hover */
}


.form-container {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 0px; /* Optional: Add border-radius for rounded corners */
  padding: 30px; /* Optional: Add padding for spacing */
}

.Tweet {
  /* Existing styles... */

  --tweet-max-height: 200px; /* Adjust the value to your desired height */
  overflow-y: auto;
}

.media-card:not(html):not(body) {
  font-family: "Architects Daughter", cursive;
  font-size: 1.25rem;
  background: transparent;
  padding: 0.5rem;
  margin: 0.5rem 0.25rem 0.25rem;
  color: #41403e;
  outline: none;
  box-shadow: 1em 1.5em 1.5em -1em rgba(0, 0, 0, 0.5);
  border-radius: 255px 15px 225px/15px 225px 15px 255px;
  box-sizing: border-box;
  border: solid 0.1em #4140344e;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 99%;
  overflow: hidden;
  
}

.media-card:nth-child(odd):not(body):not(html) {
  transform: rotate(2deg);
}
/* #E2D0F2 */
/* form */

/* .formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 0 10px;
}

.wrapper {
  width: 400px;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}

.input-field {
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 15px 0;
}

.input-field input,
textarea {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: 16px;
  pointer-events: none;
  transition: 0.15s ease;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
 */
