
/* play fair display for headings */
@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto&display=swap');
/* quick sand font for body */
@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Quicksand:wght@300..700&family=Roboto&display=swap');
/* raleway for social links */
@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Quicksand:wght@300..700&family=Raleway+Dots&family=Roboto&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      @apply bg-backgroundColor font-quicksand text-base leading-relaxed;
    }
    .section {
      @apply py-[20px] lg:py-[30px];
    }
    .title {
      @apply text-2xl lg:text-[40px] leading-tight font-semibold mb-4;
    }
    .subtitle {
      @apply text-[18px] lg:text-[20px] leading-relaxed mb-5 lg:mb-9;
    }
    .borderCard{
      @apply border hover:border-accent rounded-[18px] transition;
    }
    .buttonstyle {
      @apply 
    }
  }
  